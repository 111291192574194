<template>
  <div style="margin-top: 12px">
    <table-list :columns="columns"
                :headData="headData1"
                :tableOption="{size: 'mini'}"
                :loading="loading"
                :data="list"
                fit
                stripe>

     <!-- 回调状态码 -->
     <template #status="{row}">
       <span v-if="row.status">
         {{ row.status }}
       </span>
       <span>
         无
       </span>
      </template>

     <!-- 回调时间 -->
     <template #createTime="{row}">
        {{ tf(row.createTime, 'YYYY-MM-DD HH:mm:ss') }}
      </template>

      <!-- 回调地址 -->
     <template #callbackAddress="{row}">
       <div class="shenglue-1" v-if="row.callbackAddress">
         {{ row.callbackAddress }}
       </div>
       <span v-else>
         无
       </span>
      </template>

      <!-- 回调信息 -->
     <template #remark="{row}">
        <div class="shenglue-1 table-row" @click="handleOpen(row.remark)">{{ row.remark }}</div>
      </template>
    </table-list>
    <!-- 其他内容弹窗 -->
    <el-dialog :title="title"
               width="55%"
               :visible.sync="showDialogData">
      <pre v-if="info"
           >{{info}}</pre>
      <div v-else>暂无数据</div>
    </el-dialog>
  </div>
</template>

<script>
import TableList from '@/components/TableList'
import timeFormat from '@/utils/timeFormat.js'
import tableObj from '../component/tableData.js'
export default {
  props: {
    datas: {
      type: String,
      default: () => ''
    }
  },
  watch: {
    datas: {
      handler() {
        // console.log('回调监听:', this.datas)
        this.getData(this.datas)
      },
      deep: true,
      immediate: true
    }
  },
  components: {
    TableList
  },
  data() {
    return {
      showDialogData: false,
      title: '回调信息',
      info: '',
      loading: false,
      list: [], // 列表
      columns: tableObj.callBackData,
      headData1: tableObj.headData1
    }
  },
  created() {},
  mounted() {},
  methods: {
    // 查看详细信息
    handleOpen(value) {
      this.info = value
      this.showDialogData = true
    },
    // 时间转换
    tf(time, format) {
      return timeFormat(time, format)
    },
    async getData(id) {
      if (id) {
        this.loading = true
        let option = {
          overviewID: id
        }
        let { data, code } = await this.$api.middleWare.callback(option)
        if (code === 200) {
          // console.log('数据1:', data)
          this.list = data
        }
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .ym-table .el-table--mini td:last-child {
    padding: 4px 0 4px 24px !important;
}
.table-row {
  color: #4674e6 !important;
}
.table-row:hover {
  text-decoration: underline;
  color: #4674e6 !important;
}
// js样式
/deep/ pre {
  background: rgb(230, 230, 230);
  min-height: 400px;
  padding: 10px;
  margin: 10px;
  font-size: 16px;
  overflow-x: scroll;
}
/deep/ .el-dialog__body {
  padding: 0px 20px 20px 20px !important;
}
</style>
