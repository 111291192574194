// 账本基本字段
let tableData = [
  {
    label: '序号',
    type: 'index',
    align: 'center',
    show: true,
    width: 80
  },
  {
    label: '账本类型',
    prop: 'ledgerCodeName',
    show: true,
    align: 'center',
    width: 100
  },
  {
    label: '区块高度',
    prop: 'blockHeight',
    show: true,
    enableSlot: true,
    align: 'center',
    width: 100
  },
  {
    label: '账本哈希',
    prop: 'hash',
    show: true,
    enableSlot: true
  },
  {
    label: '创建地址',
    prop: 'sendAddress',
    show: true,
    enableSlot: true
  },
  {
    label: '执行结果',
    prop: 'errorCode',
    show: true,
    enableSlot: true,
    align: 'center',
    width: 100
  },
  {
    label: '时间戳',
    prop: 'timestamp',
    show: true,
    enableSlot: true,
    width: 190
  },
  {
    label: '备注',
    prop: 'remark',
    show: true,
    enableSlot: true,
    width: 160
  },
  {
    prop: 'operators',
    label: '操作',
    fixed: 'right',
    align: 'right',
    width: 100,
    children: [
      {
        icon: 'el-icon-view',
        label: '查看',
        showFilter: (thisVue, row) => {
          return true
        },
        clickEvent: (thisVue, row) => {
          thisVue.handleOpen(row)
        }
      }
    ]
  }
]

// 回调信息
let callBackData = [
  {
    label: '序号',
    type: 'index',
    align: 'center',
    show: true,
    width: 80
  },
  {
    label: '回调信息',
    prop: 'remark',
    align: 'center',
    show: true,
    enableSlot: true
  },
  {
    label: '回调地址',
    prop: 'callbackAddress',
    align: 'center',
    show: true,
    enableSlot: true
  },
  {
    label: '回调状态码',
    prop: 'status',
    align: 'center',
    show: true,
    enableSlot: true,
    width: 200
  },
  {
    label: '回调时间',
    prop: 'createTime',
    align: 'center',
    show: true,
    enableSlot: true,
    width: 200
  }
]

let headData = {
  title: '账本列表'
}

let headData1 = {
  title: '回调列表'
}

let tableObj = {
  'tableData': tableData,
  'headData': headData,
  'headData1': headData1,
  'callBackData': callBackData
}

export default tableObj
