var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('table-list',{attrs:{"columns":_vm.columns,"data":_vm.ledgerList,"fit":"","stripe":"","tableOption":{size: 'mini'},"loading":_vm.loading,"pageData":_vm.pageData,"headData":_vm.headData},on:{"handlePageChange":_vm.handlePageChange,"handleSizeChange":_vm.handleSizeChange},scopedSlots:_vm._u([{key:"blockHeight",fn:function(ref){
var row = ref.row;
return [(row.blockHeight)?_c('span',[_vm._v(_vm._s(_vm.toThousands(row.blockHeight)))]):_c('span',[_vm._v("无")])]}},{key:"hash",fn:function(ref){
var row = ref.row;
return [(row.hash)?_c('div',{staticClass:"div-row"},[_c('popover',{attrs:{"width":500,"shard":row.shardName,"value":row.hash,"isSubStr":false,"divClass":'shenglue-1'}}),_c('i',{staticClass:"el-icon-document-copy copy-icon",on:{"click":function($event){return _vm.copy($event, row.hash)}}})],1):(!row.hash)?_c('span',[_vm._v("无")]):_vm._e()]}},{key:"sendAddress",fn:function(ref){
var row = ref.row;
return [(row.sendAddress)?_c('div',{staticClass:"div-row"},[_c('popover',{attrs:{"width":500,"shard":row.shardName,"value":row.sendAddress,"isSubStr":false,"divClass":'shenglue-1'}}),_c('i',{staticClass:"el-icon-document-copy copy-icon",on:{"click":function($event){return _vm.copy($event, row.sendAddress)}}})],1):(!row.sendAddress)?_c('span',[_vm._v("无")]):_vm._e()]}},{key:"timestamp",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.tf(row.timestamp, 'YYYY-MM-DD HH:mm:ss'))+" ")]}},{key:"errorCode",fn:function(ref){
var row = ref.row;
return [(row.errorCode === 0 && row.result)?_c('span',[_vm._v("执行成功")]):(row.errorCode === 0 && !row.result)?_c('span',[_vm._v("执行中")]):(row.errorCode !== 0)?_c('span',[_vm._v(_vm._s(row.errorCode))]):_vm._e()]}},{key:"remark",fn:function(ref){
var row = ref.row;
return [(row.remark)?_c('popover',{attrs:{"width":500,"shard":row.shardName,"value":row.remark,"divClass":'shenglue-1',"isSubStr":false}}):_c('div',[_vm._v("无")])]}}])}),_c('el-dialog',{attrs:{"title":_vm.title,"width":"75%","visible":_vm.showDialogLedger},on:{"update:visible":function($event){_vm.showDialogLedger=$event}}},[_c('dialog-ledger',{attrs:{"datas":_vm.ledgerRow}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }