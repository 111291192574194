var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"margin-top":"12px"}},[_c('table-list',{attrs:{"columns":_vm.columns,"headData":_vm.headData1,"tableOption":{size: 'mini'},"loading":_vm.loading,"data":_vm.list,"fit":"","stripe":""},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var row = ref.row;
return [(row.status)?_c('span',[_vm._v(" "+_vm._s(row.status)+" ")]):_vm._e(),_c('span',[_vm._v(" 无 ")])]}},{key:"createTime",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.tf(row.createTime, 'YYYY-MM-DD HH:mm:ss'))+" ")]}},{key:"callbackAddress",fn:function(ref){
var row = ref.row;
return [(row.callbackAddress)?_c('div',{staticClass:"shenglue-1"},[_vm._v(" "+_vm._s(row.callbackAddress)+" ")]):_c('span',[_vm._v(" 无 ")])]}},{key:"remark",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"shenglue-1 table-row",on:{"click":function($event){return _vm.handleOpen(row.remark)}}},[_vm._v(_vm._s(row.remark))])]}}])}),_c('el-dialog',{attrs:{"title":_vm.title,"width":"55%","visible":_vm.showDialogData},on:{"update:visible":function($event){_vm.showDialogData=$event}}},[(_vm.info)?_c('pre',[_vm._v(_vm._s(_vm.info))]):_c('div',[_vm._v("暂无数据")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }