<template>
  <div class="home-page">
    <!-- 顶部组件 -->
    <search-form :form="form" @search="search"></search-form>
    <!-- 任务详情列表 -->
    <detail-list :datas="searchVal"></detail-list>
    <!-- 回调列表 -->
    <call-back-list :datas="overviewID"></call-back-list>
  </div>
</template>

<script>
import SearchForm from './component/SearchForm.vue'
import DetailList from './component/DetailList.vue'
import CallBackList from './component/CallBackList.vue'
export default {
  components: {
    SearchForm,
    DetailList,
    CallBackList
  },
  data() {
    return {
      // 表单
      form: {
        ledgerType: null, // 账本类型（默认全部）
        blockHeight: '',
        hash: '',
        sendAddress: '',
        timeList: []
      },
      searchVal: {
        ledgerType: null, // 账本类型（默认全部）
        blockHeight: '',
        hash: '',
        sendAddress: '',
        timeList: []
      },
      overviewID: ''
    }
  },
  created() {
    if (this.$route.query.overviewID) {
      this.overviewID = this.$route.query.overviewID
    }
  },
  mounted() {},
  methods: {
    // 搜索
    search(form) {
      // console.log('搜索：', form)
      this.searchVal = form
    }
  }
}
</script>
